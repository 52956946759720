import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import ApiData from './routes/ApiData';

function Home() {
  return (
    <div>
      <h1>Home Page</h1>
      <Link to="/api">Go to API Data</Link>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/api" component={ApiData} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
