import React, { useState, useEffect } from 'react';

const ApiData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API URL
    fetch('https://zl1rzxicbg.execute-api.us-east-2.amazonaws.com/react-test/react-test')
      .then((response) => response.text())
      .then((responseData) => {
        // Parse the JSON response
        const parsedData = JSON.parse(responseData);

        // Extract and set the "body" value
        setData(parsedData.body);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <h2>Raw Output from API</h2>
      {loading ? (
        <p>Loading data...</p>
      ) : (
        <pre>{data}</pre>
      )}
    </div>
  );
};

export default ApiData;
